import clsx from 'clsx';

import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import Image from 'next/future/image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import usePrice from '@/hooks/usePrice';
import LinkTo from '@/components/LinkTo';

import { IProductList } from '@/models';
import { useRouter } from 'next/router';
import { getLangCountry } from '@/helpers/languages';
import useColors from '@/hooks/useColors';

interface Props {
  data: IProductList;
  color?: string | null;
  _onClick?: () => void;
}

const ProductCard: React.FC<Props> = ({ data, color, _onClick }) => {
  const {
    name,
    price,
    pricesByColor,
    discountsByColor,
    slug,
    tags = [],
    badges = [],
    short_description,
    gallery: { images = [] },
    made_in
  } = data;
  const { t } = useTranslation();
  const [formatPrice] = usePrice();
  const { getColorLabel } = useColors();

  const router = useRouter();
  const { lang, country } = getLangCountry(router.query);

  const [previewImage, setPreviewImage] = useState(
    images[0] || '/img/placeholder.svg'
  );
  const colorImage = color ? images.find(img => img.includes(color)) : null;

  const iconsLeft = [];
  if (
    country.toLowerCase() == 'us' &&
    made_in &&
    made_in.toLowerCase() == 'us'
  ) {
    iconsLeft.push({
      icon: '/img/made-in-usa.png',
      name: 'Made in USA'
    });
  }

  const getProductPrice = () => {
    if (price.min && price.max) {
      if (Number(price.min) !== Number(price.max)) {
        return `${formatPrice(price.min)} - ${formatPrice(price.max)}`;
      }

      return formatPrice(price.min);
    } else if (price.from) {
      return `${t('STARTING_FROM')}${formatPrice(price.from)}`;
    } else if (!price.sale_price) {
      return formatPrice(price.price);
    } else {
      return (
        <>
          <span className="text-gray-400 line-through">
            {formatPrice(price.price)}
          </span>
          <span className="font-bold"> {formatPrice(price.sale_price)}</span>
        </>
      );
    }
  };
  const getProductPriceByColor = (color: string) => {
    const priceByColor = pricesByColor[color];
    if (!priceByColor) {
      return getProductPrice();
    }
    if (priceByColor.min && priceByColor.max) {
      if (Number(priceByColor.min) !== Number(priceByColor.max)) {
        return `${formatPrice(priceByColor.min)} - ${formatPrice(
          priceByColor.max
        )}`;
      }

      return formatPrice(priceByColor.min);
    } else if (priceByColor.from) {
      return `${t('STARTING_FROM')}${formatPrice(priceByColor.from)}`;
    } else if (!priceByColor.sale_price) {
      return formatPrice(priceByColor.price);
    } else {
      return (
        <>
          <span className="text-gray-400 line-through">
            {formatPrice(priceByColor.price)}
          </span>
          <span className="font-bold">
            {' '}
            {formatPrice(priceByColor.sale_price)}
          </span>
        </>
      );
    }
  };

  const icons = filter(
    filter(
      [
        get(data, 'technology', null)!,
        get(data, 'material', null)!,
        get(data, 'award', null)!
      ],
      Boolean
    ),
    ({ icon }) => Boolean(icon)
  );

  // let discountPercentage = 0;
  // if (price.price && price.sale_price && price.sale_price < price.price) {
  //   discountPercentage = Math.round((1 - price.sale_price / price.price) * 100);
  // }
  let discount = '';
  if (color) {
    discount = discountsByColor[color];
  }
  // const productName = `${name} ${
  //   color && getColorLabel(color)
  //     ? ` - ${getColorLabel(color).toUpperCase()}`
  //     : ''
  // }`;
  const productName = `${name}`;
  return (
    <div className="group flex flex-col space-y-1.5">
      <div className="relative">
        {!isEmpty(iconsLeft) && (
          <ul className="h-min absolute inset-0 flex-col items-start space-y-3 pointer-events-none p-1 xl:p-3 w-[40px] xl:w-full xl:flex">
            {iconsLeft.map((icon, idx) => (
              <li key={idx}>
                <img
                  src={icon.icon}
                  alt={icon.name}
                  className="object-cover w-16 h-auto"
                />
                {/* <Image
                  src={icon.icon}
                  alt={icon.name}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="object-cover w-16 h-auto"
                /> */}
              </li>
            ))}
          </ul>
        )}
        <LinkTo
          href={`${slug}?color=${color}`}
          aria-label={name}
          className="cursor-pointer"
          onClick={_onClick}
        >
          <img
            src={colorImage ? colorImage : previewImage}
            alt={name}
            className="object-cover w-full h-auto"
          />
          {/* <Image
            src={previewImage}
            alt={name}
            priority
            width={0}
            height={0}
            sizes="100vw"
            className="object-cover w-full h-auto"
          /> */}
        </LinkTo>

        {!isEmpty(icons) && (
          <ul className="absolute inset-0 flex-col items-end hidden p-3 space-y-3 pointer-events-none h-min xl:flex">
            {icons.map((icon, idx) => (
              <li key={idx}>
                <img
                  src={icon.icon}
                  alt={icon.name}
                  className="object-cover w-16 h-auto"
                />
                {/* <Image
                  src={icon.icon}
                  alt={icon.name}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="object-cover w-16 h-auto"
                /> */}
              </li>
            ))}
          </ul>
        )}

        {(!isEmpty(tags) || !isEmpty(badges) || discount) && (
          <ul className="flex space-x-0.5 md:space-x-1">
            {color && discountsByColor && discount && (
              <li
                key={discount}
                style={{ backgroundColor: '#CC0C39' }}
                className="text-xs md:text-base text-white px-3 py-1.5"
              >
                {discount}
              </li>
            )}
            {!isEmpty(badges) &&
              badges.map(badge => (
                <li
                  key={badge}
                  style={{ backgroundColor: '#CC0C39' }}
                  className="text-xs md:text-base text-white px-3 py-1.5"
                >
                  {badge}
                </li>
              ))}
            {!isEmpty(tags) &&
              tags.map(tag => (
                <li
                  key={tag.label}
                  style={{ backgroundColor: tag.color }}
                  className="text-xs md:text-base text-white px-3 py-1.5"
                >
                  {tag.label}
                </li>
              ))}
          </ul>
        )}
      </div>

      <div className="flex-1 flex flex-col space-y-1.5 overflow-hidden">
        <h3
          className={clsx('text-xs md:text-base font-medium', {
            'group-hover:hidden': images.length > 1 && !color
          })}
        >
          <LinkTo
            href={`${slug}?color=${color}`}
            aria-label={name}
            onClick={_onClick}
          >
            {productName}
          </LinkTo>
        </h3>

        {!color && !isEmpty(images) && (
          <ul
            className={clsx('hidden overflow-x-auto space-x-2', {
              'group-hover:flex': images.length > 1
            })}
          >
            {images.map(
              (src, idx) =>
                idx < 4 && (
                  <li
                    key={idx}
                    className="cursor-pointer"
                    onMouseOver={() => setPreviewImage(src)}
                    onClick={() => setPreviewImage(src)}
                  >
                    <LinkTo href={`${slug}?color=${color}`} aria-label={name}>
                      <img
                        src={src}
                        alt={name}
                        onClick={_onClick}
                        className="object-cover w-10 h-auto md:w-12"
                      />
                    </LinkTo>
                  </li>
                )
            )}
            {images.length > 4 && (
              <li
                key="over-4"
                className="flex items-center justify-center w-10 h-10 font-bold text-gray-400 bg-gray-200 cursor-pointer md:w-12 md:h-12"
              >
                {`+${images.length - 4}`}
              </li>
            )}
          </ul>
        )}

        {short_description && (
          <p className="text-xs text-gray-400 md:text-base line-clamp-2">
            {short_description}
          </p>
        )}

        {/* {images.length > 1 && (
          <p className="text-xs font-bold text-gray-800 md:text-sm group-hover:hidden">
            {images.length} {t('colors')}
          </p>
        )} */}

        <p className="text-xs md:text-base">
          {color ? getProductPriceByColor(color) : getProductPrice()}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
