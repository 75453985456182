import { useEffect, useState } from 'react';
import { fetchColors } from '../fetchers/mapping';
import { ColorsCodeToLabel } from '../models/mapping';

export default function useColors() {
  const [colors, setColors] = useState<ColorsCodeToLabel>();

  useEffect(() => {
    const colorsCodeToLabel = fetchColors();
    setColors(colorsCodeToLabel);
  }, []);

  const getColorLabel = (color_code?: string) => {
    if (!color_code || !colors) return '';
    const { color = color_code } = colors[color_code] || {};
    return color;
  };

  return { getColorLabel };
}
